var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar-items',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-avatar',{staticClass:"mx-2"},[_c('img',{attrs:{"src":_vm.profile.photoURL}})]),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.profile.displayName))]),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',[_c('v-dialog',{attrs:{"max-width":"512"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("プロフィール")])],1)],1)]}}]),model:{value:(_vm.dialog.profile),callback:function ($$v) {_vm.$set(_vm.dialog, "profile", $$v)},expression:"dialog.profile"}},[_c('v-card',[_c('v-card-title',[_vm._v("プロフィール編集")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"表示名"},model:{value:(_vm.profile.displayName),callback:function ($$v) {_vm.$set(_vm.profile, "displayName", $$v)},expression:"profile.displayName"}}),_c('v-file-input',{attrs:{"rules":_vm.profilePhotoRules,"accept":"image/png, image/jpeg, image/bmp","label":"プロフィール写真","prepend-icon":"mdi-camera"},model:{value:(_vm.profile.photoFile),callback:function ($$v) {_vm.$set(_vm.profile, "photoFile", $$v)},expression:"profile.photoFile"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","text":""},on:{"click":function($event){_vm.dialog.profile = false}}},[_vm._v("閉じる")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.updateProfileWithPhoto}},[_vm._v(" 保存 ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"512"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-lock")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("パスワード")])],1)],1)]}}]),model:{value:(_vm.dialog.password),callback:function ($$v) {_vm.$set(_vm.dialog, "password", $$v)},expression:"dialog.password"}},[_c('v-card',[_c('v-form',{ref:"passwordForm"},[_c('v-card-title',[_vm._v("パスワード変更")]),_c('v-card-text',[_c('v-text-field',{attrs:{"rules":[_vm.required],"type":"password","label":"現在のパスワード"},model:{value:(_vm.profile.password),callback:function ($$v) {_vm.$set(_vm.profile, "password", $$v)},expression:"profile.password"}}),_c('v-text-field',{attrs:{"rules":[_vm.required],"type":"password","label":"新しいパスワード"},model:{value:(_vm.profile.newPassword),callback:function ($$v) {_vm.$set(_vm.profile, "newPassword", $$v)},expression:"profile.newPassword"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","text":""},on:{"click":function($event){_vm.dialog.password = false}}},[_vm._v("閉じる")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.updatePassword}},[_vm._v(" 保存 ")])],1)],1)],1)],1),_c('v-divider',{attrs:{"inset":""}}),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("ログアウト")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }