
























































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  props:{
    dataCommentaryCurrent:{
      type: Object,
      default: ()=>({}),
    }
  }, 
  data: () => ({
    requestParam: {
      company: null,
      department: null,
      driverCode: null,
      driverName: null, 
      instructorCompany: null,
      instructorDepartment: null,
      instructorCode: null,
      instructorName: null,
      recordedDate: null,
      recordedTime: null,
    },
    recordedDateMenu: false,
    recordedTimeMenu: false,
    isLoading: false,
    isShowDialogDelete: false,
    isLoadingDelete: false,
  }),
  computed:{
    ...mapGetters('CommentaryAnalysisHistoriesModule', [
      'commentaryAnalysisDetail',
    ]),
  },
  watch:{
    commentaryAnalysisDetail(val){
      this.requestParam = val
    }
  },
  async mounted(){
     this.getCommentaryDetail(this.dataCommentaryCurrent.pid)
  },
  methods: {
    ...mapActions("CommentaryAnalysisHistoriesModule", [
      "updateCommentaryAnalysisDetail",
      "getCommentaryDetail",
      "deleteCommentaryAnalysis"
    ]),
    refs(): any {
      return this.$refs;
    },
    async handleDeleteCommentaryAnalysis(){
      try {
        this.isLoadingDelete = true
        await this.deleteCommentaryAnalysis(this.dataCommentaryCurrent.id)
        this.$emit('onDeleted')
      } catch (error) {
        console.log("error: ",error);
      }
      this.isLoadingDelete = false
      this.isShowDialogDelete = false
    },
    async handleEditCommentary() {
      this.isLoading = true;
      this.updateCommentaryAnalysisDetail({
        id: this.dataCommentaryCurrent.id,
        company: this.requestParam.company,
        department: this.requestParam.department,
        driverCode: this.requestParam.driverCode,
        driverName: this.requestParam.driverName,
        instructorCompany: this.requestParam.instructorCompany,
        instructorDepartment: this.requestParam.instructorDepartment,
        instructorCode: this.requestParam.instructorCode,
        instructorName: this.requestParam.instructorName,
        recordedDate: this.requestParam.recordedDate,
        recordedTime: this.requestParam.recordedTime,
      }).then(()=>{
        this.$emit('onUpdated')
      })
      this.isLoading = false;
    },
  },
});
