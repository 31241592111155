


































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import StartDialog from '@/components/commentaryAnalysis/StartDialog.vue';
import FormCommentaryAnalysis from '@/components/commentaryAnalysis/FormCommentaryAnalysis.vue';
import Setting from '@/components/commentaryAnalysis/Setting.vue';
import Snackbar from '@/components/ui/Snackbar.vue';
import CommentaryAnalysisHistory from '@/types/commentaryAnalysisHistory';
import { routerNames } from '@/types/routerNames';

interface History extends CommentaryAnalysisHistory {
  isNew: boolean;
  id: string;
  createdAtJST: string;
}

export default Vue.extend({
  components: {
    Setting,
    StartDialog,
    Snackbar,
    FormCommentaryAnalysis
  },
  data: () => ({
    dataCommentaryCurrent: "",
    historySearch: '',
    startFormCADialog: false,
    historyHeaders: [
      { text: '会社名', value: 'company' },
      { text: '所属', value: 'department' },
      { text: '受講者コード', value: 'driverCode'},
      { text: '受講者名', value: 'driverName' },
      {
        text: '解析日時',
        value: 'createdAtJST',
      },
      { text: '', value: 'actions', filterable: false, sortable: false },
    ],
    historyItems: [] as History[],
    sortBy: 'createdAt',
    sortDesc: true,
    routerNames: routerNames,
    isNew: false,
  }),
  computed: {
    ...mapGetters('CommentaryAnalysisHistoriesModule', [
      'commentaryAnalysisHistories',
    ]),
    ...mapGetters('UiAppModule', [
      'uiApp',
    ]),
  },
  methods: {
    refs(): any {
      return this.$refs;
    },
    handleRefreshData(){
      this.refs().snackbarRef.showSnackbar({
          color: 'success',
          text: '更新に成功',
        });
      this.startFormCADialog = false
      this.listCommentaryAnalysisHistory()
    },
    handleStartFormCADialog(data:any){
      this.startFormCADialog = true
      this.dataCommentaryCurrent = data
    },
    ...mapActions('CommentaryAnalysisHistoriesModule', [
      'listCommentaryAnalysisHistory',
    ]),
  },
  created() {
    this.listCommentaryAnalysisHistory();
    setTimeout(() => {
      if (this.isNew) {
        location.reload();
      }
    }, 60000);
  },
  watch: {
    commentaryAnalysisHistories: {
      handler(newHistories) {
        const items: History[] = [];
        newHistories.forEach((history: CommentaryAnalysisHistory) => {
          const createdAt = DateTime.fromJSDate(history.createdAt as Date);
          const isNew = !history?.status || history.status !== "Done";
          if (!this.isNew && isNew) {
            this.isNew = true;
          }
          items.push({
            id: history.id,
            pid: history.pid,
            ref: history.ref,
            company: history.company,
            department: history.department,
            driverCode: history.driverCode,
            driverName: history.driverName,
            status: history.status,
            isNew: isNew,
            instructorCode: history.instructorCode,
            createdAt: createdAt,
            createdAtJST: DateTime.fromJSDate(
              history.createdAt as Date
            ).toFormat('yyyy年M月d日 H時m分'),
            updatedAt: history.updatedAt,
          } as History);
        });
        this.historyItems = items;
      },
      deep: true,
      immediate: true,
    },
  },
});
