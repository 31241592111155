









































































































import Vue from 'vue';
import { mapActions } from 'vuex';
import firebase from '@/firebase';

export default Vue.extend({
  data: () => ({
    currentUser: firebase.auth().currentUser,
    profile: {
      displayName: '',
      email: '',
      newEmail: '',
      photoURL: '',
      photoFile: null,
      password: '',
      newPassword: '',
    },
    isDefaultPhoto: true,
    dialog: {
      profile: false,
      password: false,
    },
    required: (v: any) => !!v || '入力してください',
    profilePhotoRules: [(v: any) => !v || v.size < 2000000 || '2MB以下'],
  }),
  methods: {
    ...mapActions('StorageProfilePhotoModule', [
      'createProfilePhoto',
      'getProfilePhoto',
    ]),
    refs(): any {
      return this.$refs;
    },
    updateProfile() {
      if (!this.currentUser) return;
      this.currentUser.updateProfile(this.profile).then(() => {
        this.dialog.profile = false;
      });
    },
    updateProfileWithPhoto() {
      if (!this.currentUser || !this.currentUser.providerData[0]) return;
      if (!this.profile.photoFile) {
        if (this.isDefaultPhoto) {
          this.profile.photoURL = '';
        }
        this.updateProfile();
      } else {
        this.createProfilePhoto({
          file: this.profile.photoFile,
          name: this.currentUser.uid,
        }).then((filename) => {
          this.profile.photoURL = filename;
          setTimeout(() => {
            this.getProfilePhoto({
              name: this.profile.photoURL,
            }).then((url) => {
              this.profile.photoURL = url;
            });
          }, 3000);
          this.updateProfile();
        });
      }
    },
    getCredential() {
      return firebase.auth.EmailAuthProvider.credential(
        this.profile.email,
        this.profile.password
      );
    },
    updatePassword() {
      if (!this.refs().passwordForm.validate()) {
        return;
      }
      if (!this.currentUser) return;
      this.currentUser
        .reauthenticateWithCredential(this.getCredential())
        .then(() => {
          if (!this.currentUser) return;
          this.currentUser
            .updatePassword(this.profile.newPassword)
            .then(() => {
              this.profile.password = this.profile.newPassword;
              this.profile.newPassword = '';
            })
            .catch((e) => {
              console.error(e.message);
            })
            .finally(() => {
              this.dialog.password = false;
            });
        });
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.clear();
          sessionStorage.clear();
          location.reload();
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
  created() {
    if (!this.currentUser || !this.currentUser.providerData[0]) return;
    this.profile.email = this.currentUser.providerData[0].email || '';
    this.profile.displayName =
      this.currentUser.providerData[0].displayName || this.profile.email;
    this.profile.photoURL = require('../../assets/profile_photo.jpg');
    if (this.currentUser.providerData[0].photoURL) {
      this.getProfilePhoto({
        name: this.currentUser.uid,
      }).then((url) => {
        this.profile.photoURL = url;
        this.isDefaultPhoto = false;
      });
    }
  },
});
