




























































import Vue from 'vue';
import Snackbar from '@/components/ui/Snackbar.vue';
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  components: {
    Snackbar,
  },
  data: () => ({
    gakakuTop: 10,
    gakakuBottom: 10,
    gakakuLeft: 10,
    gakakuRight: 10,
    settingDialog: false,
  }),
  created() {
    this.loadCommentaryAnalysisSettingFromCache()
  },
  computed: {
    ...mapGetters('CommentaryAnalysisSettingModule', [
      'commentaryAnalysisSetting',
    ]),
  },
  watch: {
    commentaryAnalysisSetting() {
      this.gakakuTop = this.commentaryAnalysisSetting.cropRect[0]
      this.gakakuBottom = this.commentaryAnalysisSetting.cropRect[1]
      this.gakakuLeft = this.commentaryAnalysisSetting.cropRect[2]
      this.gakakuRight = this.commentaryAnalysisSetting.cropRect[3]
    }
  },
  methods: {
    ...mapActions('CommentaryAnalysisSettingModule', ['cacheCommentaryAnalysisSetting']),
    ...mapActions('CommentaryAnalysisSettingModule', ['loadCommentaryAnalysisSettingFromCache']),
    refs(): any {
      return this.$refs;
    },
    setGakaku () {
      if (!this.gakakuTop || !this.gakakuBottom || !this.gakakuLeft || !this.gakakuRight) {
        return;
      }
      if (10 > this.gakakuTop || this.gakakuTop > 90) {
        this.refs().snackbarRef.showSnackbar({
          color: 'warning',
          text: '上は10% ~ 90%の間で設定してください。',
        });
        return;
      }
      if (10 > this.gakakuBottom || this.gakakuBottom > 90) {
        this.refs().snackbarRef.showSnackbar({
          color: 'warning',
          text: '下は10% ~ 90%の間で設定してください。',
        });
        return;
      }
      if (10 > this.gakakuLeft || this.gakakuLeft > 90) {
        this.refs().snackbarRef.showSnackbar({
          color: 'warning',
          text: '左は10% ~ 90%の間で設定してください。',
        });
        return;
      }
      if (10 > this.gakakuRight || this.gakakuRight > 90) {
        this.refs().snackbarRef.showSnackbar({
          color: 'warning',
          text: '右は10% ~ 90%の間で設定してください。',
        });
        return;
      }
      this.cacheCommentaryAnalysisSetting({
        cropRect: [Number(this.gakakuTop), Number(this.gakakuBottom), Number(this.gakakuLeft), Number(this.gakakuRight)]
      })
      this.refs().snackbarRef.showSnackbar({
        color: 'success',
        text: '画角を設定しました。',
      });
      this.settingDialog = false;
    }
  }
});
