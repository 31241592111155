var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('p',{staticClass:"text-caption"},[_vm._v(" AIによるコメンタリー映像解析を行えます。「コメンタリー映像解析を開始する」ボタンをクリックして、受講者コードや名前などと、ドライブレコーダーの前方の映像を選択してください。 ")]),(_vm.isNew)?_c('v-alert',{attrs:{"text":"","dense":"","type":"info"}},[_vm._v(" 解析には2~20分かかるため、最新の解析結果は時間を空けて確認してください。また、解析中はサーバーが混みあい、他の解析結果でエラーになることがありますので、その場合は解析が終わってから、もう一度確認してください。 ")]):_vm._e(),_c('v-card',[_c('v-card-actions',{staticClass:"mx-2"},[(_vm.uiApp.isAdmin)?_c('Setting'):_vm._e(),_c('v-spacer'),_c('StartDialog')],1),_c('v-card-text',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"会社名/所属/受講者コード/受講者名で検索","single-line":"","hide-details":""},model:{value:(_vm.historySearch),callback:function ($$v) {_vm.historySearch=$$v},expression:"historySearch"}})],1),_c('v-data-table',{attrs:{"headers":_vm.historyHeaders,"items":_vm.historyItems,"search":_vm.historySearch,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary","outlined":"","small":""},on:{"click":function (){ return _vm.handleStartFormCADialog(item); }}},[_vm._v(" 編集 ")]),_c('v-btn',{attrs:{"to":{
            name: _vm.routerNames.commentaryAnalysis,
            query: {
              pid: item.pid,
            },
          },"target":"_blank","color":"secondary","outlined":"","small":"","disabled":item.isNew,"loading":item.isNew}},[_vm._v(" 解析結果 ")])]}}],null,true)})],1),(_vm.startFormCADialog)?_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.startFormCADialog),callback:function ($$v) {_vm.startFormCADialog=$$v},expression:"startFormCADialog"}},[_c('FormCommentaryAnalysis',{attrs:{"dataCommentaryCurrent":_vm.dataCommentaryCurrent},on:{"onClose":function($event){_vm.startFormCADialog=false},"onUpdated":_vm.handleRefreshData,"onDeleted":_vm.handleRefreshData}})],1):_vm._e(),_c('Snackbar',{ref:"snackbarRef"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }