

































import Vue from 'vue';
import {mapActions} from 'vuex';
import firebase from '@/firebase';
import History from '@/components/commentaryAnalysis/History.vue';
import Profile from '@/components/user/Profile.vue';
import Snackbar from '@/components/ui/Snackbar.vue';

export default Vue.extend({
  components: {
    History,
    Profile,
    Snackbar,
  },
  data: () => ({
    tab: 0,
  }),
  created() {
    const email = firebase.auth().currentUser?.email;
    if (JSON.parse(process.env.VUE_APP_ADMIN_EMAIL).includes(email)) {
      this.cacheIsAdmin(true);
    }
    this.loadIsAdminCache();
  },
  methods: {
    ...mapActions('UiAppModule', ['cacheIsAdmin', 'loadIsAdminCache']),
  },
});
