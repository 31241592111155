





























































































































































import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import Snackbar from '@/components/ui/Snackbar.vue';

export default Vue.extend({
  components: {
    Snackbar,
  },
  data: () => ({
    requestParam: {
      company: null,
      department: null,
      driverCode: null,
      driverName: null,
      video: null as File | null,
      instructorCompany: null,
      instructorDepartment: null,
      instructorCode: null,
      instructorName: null,
      recordedDate: null,
      recordedTime: null,
    },
    recordedDateMenu: false,
    recordedTimeMenu: false,
    requiredRules: [(v: any) => !!v || ''],
    startCommentaryAnalysisDialog: false,
    uploading: false,
  }),
  created() {
    this.loadCommentaryAnalysisBasicInfoFromCache()
    this.loadCommentaryAnalysisSettingFromCache()
  },
  computed: {
    ...mapGetters('CommentaryAnalysisBasicInfoModule', [
      'commentaryAnalysisBasicInfo',
    ]),
    ...mapGetters('CommentaryAnalysisSettingModule', [
      'commentaryAnalysisSetting',
    ]),
  },
  watch: {
    commentaryAnalysisBasicInfo() {
      this.requestParam.company = this.commentaryAnalysisBasicInfo.company
      this.requestParam.department = this.commentaryAnalysisBasicInfo.department
      this.requestParam.instructorCompany = this.commentaryAnalysisBasicInfo.instructorCompany
      this.requestParam.instructorDepartment = this.commentaryAnalysisBasicInfo.instructorDepartment
      this.requestParam.instructorCode = this.commentaryAnalysisBasicInfo.instructorCode
      this.requestParam.instructorName = this.commentaryAnalysisBasicInfo.instructorName
    }
  },
  methods: {
    ...mapActions('CommentaryAnalysisModule', ['analyzeCommentaryVideo']),
    ...mapActions('CommentaryAnalysisHistoriesModule', [
      'createCommentaryAnalysisHistory',
    ]),
    ...mapActions('CommentaryAnalysisBasicInfoModule', ['cacheCommentaryAnalysisBasicInfo', 'loadCommentaryAnalysisBasicInfoFromCache']),
    ...mapActions('CommentaryAnalysisSettingModule', ['loadCommentaryAnalysisSettingFromCache']),
    refs(): any {
      return this.$refs;
    },
    async startAnalyzeVideo() {
      if (!this.refs().analyzeVideoForm.validate()) {
        return;
      }
      if (this.requestParam.video && this.requestParam.video?.size > 500000000) {
        alert('500MB以下の映像を選択してください。解析時間の上限を超えることがあります。');
        return;
      }
      this.uploading = true;
      const pid = await this.analyzeCommentaryVideo({
        video: this.requestParam.video,
            cropRect: this.commentaryAnalysisSetting.cropRect.reduce((a: number, b: number) => a + b, 0) > 60 ? 25: 10,
      });
      if (pid !== '') {
        this.createCommentaryAnalysisHistory({
          pid: pid,
          company: this.requestParam.company,
          department: this.requestParam.department,
          driverCode: this.requestParam.driverCode,
          driverName: this.requestParam.driverName,
          instructorCompany: this.requestParam.instructorCompany,
          instructorDepartment: this.requestParam.instructorDepartment,
          instructorCode: this.requestParam.instructorCode,
          instructorName: this.requestParam.instructorName,
          videoSize: this.requestParam.video?.size,
          recordedDate: this.requestParam.recordedDate,
          recordedTime: this.requestParam.recordedTime,
        }).then(() => {
          this.refs().snackbarRef.showSnackbar({
            color: 'success',
            text: '解析を開始しました。',
          });
          this.startCommentaryAnalysisDialog = false;
          this.uploading = false;
          location.reload();
        });
        this.cacheCommentaryAnalysisBasicInfo({
          company: this.requestParam.company,
          department: this.requestParam.department,
          instructorCompany: this.requestParam.instructorCompany,
          instructorDepartment: this.requestParam.instructorDepartment,
          instructorCode: this.requestParam.instructorCode,
          instructorName: this.requestParam.instructorName,
        })
      } else {
        this.refs().snackbarRef.showSnackbar({
          color: 'error',
          text: 'アップロードに失敗しました。',
        });
        this.startCommentaryAnalysisDialog = false;
        this.uploading = false;
      }
    },
  },
});
